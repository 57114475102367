import React, { useEffect, useState } from 'react'

import { useTranslation } from 'gatsby-plugin-react-i18next'

import { useI18next } from 'gatsby-plugin-react-i18next'
// import { loadStorage, saveStorage, clearStorage } from 'src/lib/storage'

import { get } from 'src/lib/api'

import Link from 'src/components/link/index'

import styles from './input.module.scss'

const Input = ({ complete }) => {
	const { language, t } = useI18next()

	const [types, setTypes] = useState([])

	const [values, setValues] = useState({
		type: ``, //loadStorage(`type`),
		name: ``, //loadStorage(`name`),
		email: ``, //loadStorage(`email`),
		content: ``, //loadStorage(`content`)
	})

	const [disabled, setDisabled] = useState(false)

	const change = (e) => {
		// saveStorage(e.target.name, e.target.value)
		setValues({ ...values, [e.target.name]: e.target.value })
	}

	const submit = (e) => {
		e.preventDefault()
		setDisabled(true)

		fetch(`https://loquat-nishiizu.jp/app/api/contact/save`, {
			mode: 'cors',
			method: `POST`,
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(values),
		})
			.then((response) => {
				return response.json()
			})
			.then((result) => {
				if (result.code === 0) {
					// clearStorage()
					complete()
				} else {
					alert('Error')
				}
			})
			.catch(() => {
				alert('Error')
			})
			.finally(() => {
				setDisabled(false)
			})
	}

	useEffect(() => {
		get(`/app/api/contact/types`, { lang: language }).then((data) => {
			setTypes(data)
		})
	}, [setTypes])

	const confirmTexts = t(`input.confirm`).split('%')

	return (
		<div>
			<div className={styles.text}>
				<p dangerouslySetInnerHTML={{ __html: t(`input.lead`) }}></p>
			</div>

			<form onSubmit={submit} className={styles.form}>
				<dl>
					<dt>
						<label htmlFor="input_type">{t(`input.type`)}</label>
					</dt>
					<dd>
						<div className={styles.select}>
							<select name="type" id="input_type" value={values.type} onChange={change} onBlur={change} required>
								<option value="">{t(`input.select`)}</option>
								{types.map((type, i) => (
									<option key={i} value={type.slug}>
										{type.name}
									</option>
								))}
							</select>
						</div>
					</dd>
				</dl>
				<dl>
					<dt>
						<label htmlFor="input_name">{t(`input.name`)}</label>
					</dt>
					<dd>
						<input type="text" name="name" value={values.name} onChange={change} id="input_name" required></input>
					</dd>
				</dl>
				<dl>
					<dt>
						<label htmlFor="input_email">{t(`input.email`)}</label>
					</dt>
					<dd>
						<input type="email" name="email" value={values.email} onChange={change} id="input_email" required></input>
					</dd>
				</dl>
				<dl>
					<dt>
						<label htmlFor="input_content">{t(`input.content`)}</label>
					</dt>
					<dd>
						<textarea name="content" value={values.content} onChange={change} id="input_content"></textarea>
					</dd>
				</dl>
				<dl>
					<dt></dt>
					<dd>
						<div className={styles.privacy}>
							<p>
								{confirmTexts[0]}
								<Link to={`/pdf/${language}/privacy_policy.pdf`}>{confirmTexts[1]}</Link>
								{confirmTexts[2]}
							</p>
						</div>
						<input type="submit" value={t(`input.submit`)} disabled={disabled} />
					</dd>
				</dl>
			</form>
		</div>
	)
}

export default Input
