import React, { useState } from 'react'

import { useTranslation } from 'gatsby-plugin-react-i18next'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import SEO from 'src/components/seo/index'
import Input from 'src/components/contact/input'
import Complete from 'src/components/contact/complete'

import styles from './index.module.scss'

const ContactPage = () => {
	const { t } = useTranslation()

	const [status, setStatus] = useState(0)

	const complete = () => {
		setStatus(1)
	}

	const scroll = () => {
		document.body.scrollTop = document.documentElement.scrollTop = 0
	}

	return (
		<div className={styles.contact}>
			<SEO title={t(`meta.title`)} description={t(`meta.description`)} />

			<h1 className={styles.title}>Contact</h1>

			<SwitchTransition>
				<CSSTransition key={status} timeout={500} classNames={styles} onEnter={scroll}>
					<React.Fragment>{status === 0 ? <Input complete={complete} /> : <Complete />}</React.Fragment>
				</CSSTransition>
			</SwitchTransition>
		</div>
	)
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { ns: { in: ["common", "contact"] }, language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`
export default ContactPage
