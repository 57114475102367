import React from 'react'

import { useTranslation } from 'gatsby-plugin-react-i18next'

import Link from 'src/components/link/index'

import styles from './complete.module.scss'

const Complete = () => {
	const { t } = useTranslation()

	return (
		<div className={styles.complete}>
			<div>
				<div className={styles.text}>
					<p dangerouslySetInnerHTML={{ __html: t(`complete.title`) }}></p>
					<p dangerouslySetInnerHTML={{ __html: t(`complete.text`) }}></p>
				</div>
				<div className={styles.button}>
					<Link to="/">{t(`back`)}</Link>
				</div>
			</div>
		</div>
	)
}

export default Complete
